
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.sticky-module.affix {
    width: var(--sticky-module-width);
    z-index: 1;

    + * {
        padding-top: var(--sticky-module-height);
    }
}
