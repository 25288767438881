
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.v-select--chips::v-deep {
    &.v-select.v-text-field--enclosed:not(.v-text-field--single-line):not(
            .v-text-field--outlined
        )
        .v-select__selections {
        padding-top: 24px;
        margin-left: -4px;

        .v-chip.v-size--small {
            padding: 0 8px;
        }
    }

    &.input-disabled {
        input {
            visibility: hidden;
            pointer-events: none;
        }
    }

    .v-input__slot:hover {
        .v-chip {
            background-color: $seashell-solid;
        }
    }
}
