
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.zoom-box {
    transition: 0.5s ease-in-out;
    transform-origin: center;
    position: absolute;
}
