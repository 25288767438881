
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.amp-video-preview::v-deep {
    background-color: $black;

    &.zoom {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 5; // appear on top of the app top header
        height: calc(100vh - calc(100vh - 100%)) !important;
        width: calc(100vw - calc(100vh - 100%)) !important;
        min-height: -webkit-fill-available !important;
        min-width: -webkit-fill-available !important;
        transform: translate3d(0, 0, 0);

        .v-carousel__item {
            height: 100% !important;
        }

        .v-carousel__controls {
            transform: translateY(100%);
            opacity: 0;
            transition: 0.2s ease;
        }

        .actions {
            transform: translateY(-100%);
            opacity: 0;
            transition: 0.2s ease;
        }
    }

    &.light {
        background-color: $grey-light-darker;
    }

    .slide-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transition: transform 0.5s ease-in-out;
        transform: translate(-50%, -50%);
        transform-origin: top left;
        overflow: hidden;
    }

    .progress-indicator {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .v-item--active.v-btn--active.v-btn--icon {
        color: $primary-color;
    }

    .v-carousel__controls {
        .v-carousel__controls__item {
            &:after {
                content: attr(value);
                position: absolute;
                color: $white;
                top: calc(-100% + 3px);

                transform: translateY(400%);
                opacity: 0;
                transition: 0.2s ease;
            }

            .v-btn__content:before {
                content: '';
                position: absolute;
                background-color: $primary-color;
                border-radius: 50% 50% 0;
                min-width: 22px;
                min-height: 22px;
                top: calc(-100% - 14px);

                transform: translateY(400%) rotate(45deg);
                opacity: 0;
                transition: 0.2s ease;
            }
        }
    }

    &:hover {
        .actions {
            transform: translateY(0);
            opacity: 1;
        }

        .v-carousel__controls {
            transform: translateY(0);
            opacity: 1;

            .v-carousel__controls__item {
                &:after {
                    transform: translateY(0);
                    opacity: 1;
                }

                .v-btn__content:before {
                    transform: translateY(0) rotate(45deg);
                    opacity: 1;
                }

                &.v-item--active {
                    &:after {
                        transform: translateY(-2px) scale(1.3);
                    }

                    .v-btn__content:before {
                        transform: translateY(-2px) rotate(45deg) scale(1.3);
                    }
                }
            }
        }
    }
}
