
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.actions {
    background-color: rgba(map-get($shades, 'black'), 0.3);
    position: relative;
    z-index: 1; // appear on top of the slide
}
